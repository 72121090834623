export const getCheckoutType = (routeType, key) => {
  let type = routeType;
  try {
    if (!routeType) {
      const sessionType = window.sessionStorage.getItem(`checkout_type_${key}`);
      if (sessionType) {
        type = sessionType;
      }
    }
  } catch (error) {}
  return type;
};

export const sendGaResultEvent = (routeType, key, orderInfo, eventName) => {
  try {
    let checkout_type = '其它';
    const type = getCheckoutType(routeType, key);
    if (type === '1') {
      checkout_type = '卡支付';
    } else if (type === '2') {
      checkout_type = '聚合';
    }
    const params = {
      key,
      merchant_id: orderInfo && orderInfo.merchantNo,
      txn_id: orderInfo && orderInfo.txnId,
      checkout_type,
    };
    window.gtag('event', eventName, params);
  } catch (error) {
  }
};
